<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <h1 class="text-h4 primary--text"
          >Wyoming Property Tax Refund Program</h1
        >
        <h2 class="text-subtitle-1 primary--text">Filing Instructions</h2>
      </v-col>
      <v-col sm="12">
        <Instructions></Instructions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Instructions from '@components/instructions.vue'
export default {
  page: {
    title: 'Filing Instructions',
  },
  components: {
    Instructions,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
